<template>
  <!-- 主页 -->
  <div class="home">
    <!-- 第一页 -->
    <div class="page1">
      <!-- Logo -->
      <div class="box">
        <van-image
          :src="require('../../assets/home/home_page1_logo.png')"
          class="logo"
        />
        <van-image
          :src="require('../../assets/home/home_page1_logo_txt.png')"
          class="logo_txt"
        />
        <div class="logo_txt_2">一键记录植物成长</div>
      </div>
      <!-- 下载App -->
      <div class="btn" @click="dow_app">下载App</div>
      <!-- 查看更多 -->
      <div class="icon" @click="home_slip">
        <van-icon name="arrow-down" />
        <van-icon name="arrow-down" />
      </div>
      <div class="icon_txt" @click="home_slip">查看更多</div>
    </div>
    <!-- 第二页 -->
    <div class="page2">
      <!-- 感谢信 -->
      <div class="box">
        <div class="txt1">感恩遇见，<br />把感谢写在前面</div>
        <div class="txt2">感谢以下开发设计多年来给予我们的莫大帮助:</div>
        <div class="txt3">
          <span>@lany</span>
          <span>@咕嘟</span>
          <span>@熊熊</span>
          <span>@肉卷是章鱼</span>
          <span>@Zxlin</span>
          <span>@Drui</span>
          <span>@小坤</span>
          <span>@Null</span>
          <span>@finall</span>
          <br />……
          <div>也希望更多人加入我们</div>
        </div>
        <div class="txt4">
          最后，感谢努力坚持的我们<br />和一直都在的<br /><span>你</span>
        </div>
        <div class="txt5">
          愿每一份热爱都被温柔相待<br />愿每一份坚持都能如花盛开
        </div>
      </div>
      <!-- 尾部 -->
      <div class="bot">
        <div class="txt txt1">许可证：福建自然生长科技有限公司</div>
        <div class="txt txt2">
          <a href="https://beian.miit.gov.cn" style="color:#f7f7fa">备案号：闽ICP备2021013367号-1</a>
          </div>
      </div>
    </div>
    <TipMask :visible="showMask" @toggle="showMask = !showMask"></TipMask>
  </div>
</template>

<script>
import $ from "jquery";
import { Toast } from "vant";
import TipMask from '../../components/TipMask.vue'
export default {
  name: "Home",
  components: {
    TipMask
  },
  mounted() {
    document.title = this.home_title;
    //只允许移动端打开
    this.appUrl.onlyMo(); //只允许移动端打开
    //判断屏幕比例
    $(".home .page2 .box").css({ height: $(window).height() - 165 + "px" });
    if($(window).width()/$(window).height() > 0.5){
      $(".home .page1").css("background-size","100%");
    }
  },
  data() {
    return {
      showMask: false,
      home_title: "主页", //页面title
      down_url_iphone: this.appUrl.down_url_iphone, //Iphone下载链接
      down_url_android: this.appUrl.down_url_android, //Android下载链接
    };
  },
  methods: {
    home_slip() {
      //换页
      $("#app")
        .stop()
        .animate({ scrollTop: $(window).height() }, 600);
    },
    dow_app() {
      //下载app
      var ua = navigator.userAgent.toLowerCase();
      //系统判断
      if ((ua.indexOf('qq')>-1 && ua.indexOf('mqqbrowser')<0) ||
        ua.match(/MicroMessenger/i) == 'micromessenger') {
          // qq 或微信浏览器
        this.showMask = true
      } else if (ua.match(/iPhone/i) == "iphone") {
        //iphone
        console.log(ua.match(/iPhone/i));
        window.open(this.down_url_iphone);
      } else if (ua.match(/Android/i) == "android") {
        //android
        console.log(ua.match(/Android/i));
        window.open(this.down_url_android);
      } else {
        console.log("请使用移动端登录");
        Toast.fail("请前往移动端下载");
      }
    },
  },
};
</script>
<style>
.home {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: PingFang-SC-Medium, PingFang-SC;
}
.home .page1 {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/home/home_page1_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
}
.home .page1 .box {
  position: relative;
  top: 0;
  left: 0;
  height: 400px;
  background: linear-gradient(180deg, #f7f7f7 0%, rgba(255, 255, 255, 0) 100%);
}
.home .page1 .logo {
  position: absolute;
  top: 80px;
  width: 100px;
  height: 100px;
  left: 0;
  right: 0;
  margin: auto;
}
.home .page1 .logo_txt {
  position: absolute;
  top: 196px;
  width: 84px;
  left: 0;
  right: 0;
  margin: auto;
}
.home .page1 .logo_txt_2 {
  position: absolute;
  top: 237px;
  width: 144px;
  height: 25px;
  font-size: 18px;
  color: #26262a;
  line-height: 25px;
  left: 0;
  right: 0;
  margin: auto;
}
.home .page1 .btn {
  position: absolute;
  bottom: 120px;
  text-align: center;
  width: 188px;
  height: 44px;
  background: #26262a;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
  line-height: 44px;
  left: 0;
  right: 0;
  margin: auto;
}
.home .page1 .icon {
  position: absolute;
  bottom: 78px;
  color: #26262a;
  width: 16px;
  height: 12px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 18px;
}
.home .page1 .icon i:nth-child(1) {
  bottom: 11px;
  -webkit-animation: homePage1Icon 0.8s linear 0s infinite normal both;
  animation: homePage1Icon 0.8s linear 0s infinite normal both;
}
.home .page1 .icon i:nth-child(2) {
  bottom: 26px;
  -webkit-animation: homePage1Icon 0.8s linear 0.1s infinite normal both;
  animation: homePage1Icon 0.8s linear 0.1s infinite normal both;
}
@keyframes homePage1Icon {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  6% {
    opacity: 1;
  }
  61% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.home .page1 .icon_txt {
  position: absolute;
  bottom: 52px;
  height: 20px;
  font-size: 14px;
  color: #26262a;
  line-height: 20px;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  font-family: PingFang-SC-Bold, PingFang-SC;
}
.home .page2 {
  height: 100%;
  position: relative;
  background-image: url("../../assets/home/home_page2_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.home .page2 .box {
  position: relative;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  width: 85.33%;
  background-image: url("../../assets/home/home_page2_box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  font-weight: bold;
}
.home .page2 .box .txt1 {
  max-width: 90%;
  position: absolute;
  top: 12%;
  left: 18px;
  font-size: 20px;
  color: #26262a;
  line-height: 28px;
  font-family: PingFang-SC-Heavy, PingFang-SC;
}
.home .page2 .box .txt2 {
  max-width: 90%;
  position: absolute;
  top: 29.33%;
  left: 18px;
  font-size: 14px;
  color: #26262a;
  line-height: 20px;
  font-family: PingFang-SC-Medium, PingFang-SC;
}
.home .page2 .box .txt3 {
  max-width: 90%;
  position: absolute;
  top: 35.82%;
  left: 18px;
  font-size: 15px;
  color: #9a845a;
  line-height: 24px;
  font-family: PingFang-SC-Bold, PingFang-SC;
}
.home .page2 .box .txt3 span {
  display: inline-block;
  padding-right: 20px;
}
.home .page2 .box .txt3 div {
  color: #26262a;
}
.home .page2 .box .txt3 span:nth-last-of-type(1) {
  padding-right: 0;
}
.home .page2 .box .txt4 {
  max-width: 90%;
  position: absolute;
  top: 65%;
  left: 18px;
  font-size: 16px;
  color: #26262a;
  line-height: 24px;
  font-family: PingFang-SC-Bold, PingFang-SC;
}
.home .page2 .box .txt4 span {
  font-size: 24px;
}
.home .page2 .box .txt5 {
  max-width: 90%;
  position: absolute;
  top: 84.66%;
  left: 18px;
  font-size: 15px;
  color: #26262a;
  line-height: 24px;
  font-family: PingFang-SC-Medium, PingFang-SC;
}
.home .page2 .bot {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 87px;
  background: rgba(38, 38, 42, 0.9);
}
.home .page2 .bot .txt {
  position: absolute;
  height: 14px;
  font-size: 13px;
  color: #f7f7fa;
  line-height: 13px;
  font-weight: 300;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
}
.home .page2 .bot .txt1 {
  top: 28px;
}
.home .page2 .bot .txt2 {
  bottom: 21px;
}
</style>
